import $ from 'jquery';

export default () => {



    // Open Search / Navi

    $('.header-search__icon').click(function() {
        $(this).closest('header').toggleClass('search--active');
        $(this).closest('header').removeClass('navigation--active');
        $('.header-nav').find('.open').each(function() {
            $(this).removeClass('open');
        });
    });

    $('.header-nav .parent--nav-item').click(function() {
        if (!$(this).closest('.nav-menu').hasClass("open")) {
            $(this).closest('header').addClass('navigation--active');
            $(this).closest('header').removeClass('search--active');
            $(this).closest('.nav-menu').addClass('open');
            $(this).closest('.nav-menu').siblings('.open').removeClass('open');
        } else {
            $(this).closest('header').removeClass('navigation--active');
            $(this).closest('.nav-menu').removeClass('open');
        }

        // Mega Menü Hight Calc
        var t = 0; // the height of the highest element (after the function runs)
        var childElem = $(this).closest('.nav-menu').find('.dropdown');
        $(childElem).each(function () {
            var elem = $(this);
            elem.height('auto');
            if (elem.height() > t) {
                t = elem.height();
            }
        });
        $(childElem).height(t);
    });

    $('.dropdown-menu > li > .dropdown-item').click(function() {
        $(this).closest('li').addClass('open');
        $(this).closest('li').siblings('.open').removeClass('open');
    });

    $('.navigation--close').click(function() {
        $(this).closest('header').removeClass('navigation--active');
        $(this).closest('.nav-menu').removeClass('open');
    });



    // Make fixed Navigation
    $(window).bind('scroll', function () {
        if ($(window).scrollTop() > 200) {
            $('header').addClass('fixed-header');
        } else {
            $('header').removeClass('fixed-header');
        }
    });

    // Navigation Touch Geräte
    $('#headerNavbar ul.navbar-nav > li.nav-menu > a').on('touchend', function (e) {
        if (!$(this).parent().hasClass('hovered')) {
            e.preventDefault();
            $(this).parent().toggleClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
        }
    });

    const hoveredItems = $('[class*="hovered"]');

    hoveredItems.each(function () {
        $(this).removeClass('.hovered');
    });


    // Section Controller
    // Gradien Section
    $('.bg--gradient-blue-green').each(function() {
        if($(this).next().hasClass('bg--white') && $(this).prev().hasClass('bg--blue')) {
            $(this).addClass('bg--white--after');
        }
        if($(this).next().hasClass('bg--blue') && $(this).prev().hasClass('bg--white')) {
            $(this).addClass('bg--blue--after');
        }
    });

    // Other Section
    $('.section__wrapper:not(.bg--gradient-blue-green)').each(function() {
        if($(this).hasClass('bg--white') && $(this).prev().hasClass('bg--blue')) {
            $(this).addClass('bg--blue--after');
        }
        if($(this).hasClass('bg--blue') && $(this).prev().hasClass('bg--white')) {
            $(this).addClass('bg--white--after');
        }
    });

    // Sections with CTA inside
    $('.cta__wrapper:last-child').each(function() {
        $(this).closest('.section__wrapper').addClass('cta-at-last--inside');
    });

    // Header followed with youtube
    $('.wly-youtube-wrapper').prev('.header__wrapper').addClass('followed-by--youtube');



    // Blog Author Hover
    $(".header__content__team").each(function (index) {
        $(this).hover(
            function () {
                $(".author__wrapper").addClass(`hover-elm--${index + 1}`);
            },
            function () {
                $(".author__wrapper").removeClass(`hover-elm--${index + 1}`);
            }
        );
    });
};
